import * as React from "react"
import { Link } from "gatsby"
import { postlistContainer } from "./postlist.module.css"
import { PostItemType } from "../interfaces"

type PostListProps = {
  posts: Array<PostItemType>
}

const PostList = ({ posts }: PostListProps) => {
  return (
    <div className={postlistContainer}>
      {posts.map((post) => (
        <div key={post.id}>
          <h3>
            <Link to={`/blog/${post.slug}`}>{post.frontmatter.title}</Link>
          </h3>
          <p>Posted on {post.frontmatter.date}</p>
        </div>
      ))}
    </div>
  )
}

export default PostList
