import * as React from "react"
import { graphql } from "gatsby"
import type { PageProps } from "gatsby"
import Layout from "../../components/layout"
import PostList from "../../components/postlist"
import type { MDXListType } from "../../interfaces"

const BlogPage = ({ data }: PageProps<MDXListType>) => {
  return (
    <Layout pageTitle="All Posts">
      <PostList posts={data.allMdx.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

export default BlogPage
